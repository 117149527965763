<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
      >
        <stats-card header-color="indigo">
          <template slot="header">
            <div class="card-icon" style="margin-right: auto;">
              <router-link
                to="/mosaico/covid/chart/brasilio/covid/cases/per_date"
              >
                <md-icon>insert_chart_outlined</md-icon>
              </router-link>
            </div>
            <p class="category">Gráficos</p>
            <router-link
              to="/mosaico/covid/chart/brasilio/covid/cases/per_date"
            >
              <h3 class="title">
                Casos por dia
              </h3>
            </router-link>
          </template>

          <template slot="footer">
            <div class="stats">
              <md-icon>today</md-icon>
              Fonte: Brasil.Io
            </div>
          </template>
        </stats-card>
      </div>
      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
      >
        <stats-card header-color="indigo">
          <template slot="header">
            <div class="card-icon" style="margin-right: auto;">
              <router-link
                to="/mosaico/covid/chart/brasilio/covid/cases/incidence"
              >
                <md-icon>insert_chart_outlined</md-icon>
              </router-link>
            </div>
            <p class="category">Gráficos</p>
            <router-link
              to="/mosaico/covid/chart/brasilio/covid/cases/incidence"
            >
              <h3 class="title">
                Incidência de casos por dia
              </h3>
            </router-link>
          </template>

          <template slot="footer">
            <div class="stats">
              <md-icon>today</md-icon>
              Fonte: Brasil.Io
            </div>
          </template>
        </stats-card>
      </div>
      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
      >
        <stats-card header-color="indigo">
          <template slot="header">
            <div class="card-icon" style="margin-right: auto;">
              <router-link
                to="/mosaico/covid/chart/brasilio/covid/cases/moving_agv"
              >
                <md-icon>insert_chart_outlined</md-icon>
              </router-link>
            </div>
            <p class="category">Gráficos</p>
            <router-link
              to="/mosaico/covid/chart/brasilio/covid/cases/moving_agv"
            >
              <h3 class="title">
                Média Móvel de casos
              </h3>
            </router-link>
          </template>

          <template slot="footer">
            <div class="stats">
              <md-icon>today</md-icon>
              Fonte: Brasil.Io
            </div>
          </template>
        </stats-card>
      </div>
      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
      >
        <stats-card header-color="indigo">
          <template slot="header">
            <div class="card-icon" style="margin-right: auto;">
              <router-link
                to="/mosaico/covid/chart/brasilio/covid/cases/incidence_moving_agv"
              >
                <md-icon>insert_chart_outlined</md-icon>
              </router-link>
            </div>
            <p class="category">Gráficos</p>
            <router-link
              to="/mosaico/covid/chart/brasilio/covid/cases/incidence_moving_agv"
            >
              <h3 class="title">
                Incidência da Média Móvel de casos
              </h3>
            </router-link>
          </template>

          <template slot="footer">
            <div class="stats">
              <md-icon>today</md-icon>
              Fonte: Brasil.Io
            </div>
          </template>
        </stats-card>
      </div>
    </div>
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
      >
        <stats-card header-color="danger">
          <template slot="header">
            <div class="card-icon" style="margin-right: auto;">
              <router-link
                to="/mosaico/covid/chart/brasilio/covid/deaths/per_date"
              >
                <md-icon>insert_chart</md-icon>
              </router-link>
            </div>
            <p class="category">Gráficos</p>
            <router-link
              to="/mosaico/covid/chart/brasilio/covid/deaths/per_date"
            >
              <h3 class="title">
                Óbitos por dia
              </h3>
            </router-link>
          </template>

          <template slot="footer">
            <div class="stats">
              <md-icon>today</md-icon>
              Fonte: Brasil.Io
            </div>
          </template>
        </stats-card>
      </div>
      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
      >
        <stats-card header-color="danger">
          <template slot="header">
            <div class="card-icon" style="margin-right: auto;">
              <router-link
                to="/mosaico/covid/chart/brasilio/covid/deaths/incidence"
              >
                <md-icon>insert_chart</md-icon>
              </router-link>
            </div>
            <p class="category">Gráficos</p>
            <router-link
              to="/mosaico/covid/chart/brasilio/covid/deaths/incidence"
            >
              <h3 class="title">
                Incidência de óbitos por dia
              </h3>
            </router-link>
          </template>

          <template slot="footer">
            <div class="stats">
              <md-icon>today</md-icon>
              Fonte: Brasil.Io
            </div>
          </template>
        </stats-card>
      </div>
      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
      >
        <stats-card header-color="danger">
          <template slot="header">
            <div class="card-icon" style="margin-right: auto;">
              <router-link
                to="/mosaico/covid/chart/brasilio/covid/deaths/moving_agv"
              >
                <md-icon>insert_chart</md-icon>
              </router-link>
            </div>
            <p class="category">Gráficos</p>
            <router-link
              to="/mosaico/covid/chart/brasilio/covid/deaths/moving_agv"
            >
              <h3 class="title">
                Média Móvel de óbitos
              </h3>
            </router-link>
          </template>

          <template slot="footer">
            <div class="stats">
              <md-icon>today</md-icon>
              Fonte: Brasil.Io
            </div>
          </template>
        </stats-card>
      </div>
      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
      >
        <stats-card header-color="danger">
          <template slot="header">
            <div class="card-icon" style="margin-right: auto;">
              <router-link
                to="/mosaico/covid/chart/brasilio/covid/deaths/incidence_moving_agv"
              >
                <md-icon>insert_chart</md-icon>
              </router-link>
            </div>
            <p class="category">Gráficos</p>
            <router-link
              to="/mosaico/covid/chart/brasilio/covid/deaths/incidence_moving_agv"
            >
              <h3 class="title">
                Incidência da Média Móvel de Obitos
              </h3>
            </router-link>
          </template>

          <template slot="footer">
            <div class="stats">
              <md-icon>today</md-icon>
              Fonte: Brasil.Io
            </div>
          </template>
        </stats-card>
      </div>
    </div>
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
      >
        <stats-card header-color="green">
          <template slot="header">
            <div class="card-icon" style="margin-right: auto;">
              <router-link to="/mosaico/covid/maps/brasilio/covid/region">
                <md-icon>map</md-icon>
              </router-link>
            </div>
            <p class="category">Mapas</p>
            <router-link to="/mosaico/covid/maps/brasilio/covid/region">
              <h3 class="title">
                Mapas por regiões
              </h3>
            </router-link>
          </template>

          <template slot="footer">
            <div class="stats">
              <md-icon>today</md-icon>
              Fonte: Brasil.Io
            </div>
          </template>
        </stats-card>
      </div>
      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
      >
        <stats-card header-color="green">
          <template slot="header">
            <div class="card-icon" style="margin-right: auto;">
              <router-link to="/mosaico/covid/maps/brasilio/covid/state">
                <md-icon>map</md-icon>
              </router-link>
            </div>
            <p class="category">Mapas</p>
            <router-link to="/mosaico/covid/maps/brasilio/covid/state">
              <h3 class="title">
                Mapas por estados
              </h3>
            </router-link>
          </template>

          <template slot="footer">
            <div class="stats">
              <md-icon>today</md-icon>
              Fonte: Brasil.Io
            </div>
          </template>
        </stats-card>
      </div>
      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
      >
        <stats-card header-color="green">
          <template slot="header">
            <div class="card-icon" style="margin-right: auto;">
              <router-link to="/mosaico/covid/maps/brasilio/covid/city">
                <md-icon>map</md-icon>
              </router-link>
            </div>
            <p class="category">Mapas</p>
            <router-link to="/mosaico/covid/maps/brasilio/covid/city">
              <h3 class="title">
                Mapas por cidades
              </h3>
            </router-link>
          </template>

          <template slot="footer">
            <div class="stats">
              <md-icon>today</md-icon>
              Fonte: Brasil.Io
            </div>
          </template>
        </stats-card>
      </div>
      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
      >
        <stats-card header-color="green">
          <template slot="header">
            <div class="card-icon" style="margin-right: auto;">
              <router-link to="/mosaico/covid/maps/brasilio/covid/mesoregion">
                <md-icon>map</md-icon>
              </router-link>
            </div>
            <p class="category">Mapas</p>
            <router-link to="/mosaico/covid/maps/brasilio/covid/mesoregion">
              <h3 class="title">
                Mapas por mesoregião
              </h3>
            </router-link>
          </template>

          <template slot="footer">
            <div class="stats">
              <md-icon>today</md-icon>
              Fonte: Brasil.Io
            </div>
          </template>
        </stats-card>
      </div>
    </div>
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
      >
        <stats-card header-color="green">
          <template slot="header">
            <div class="card-icon" style="margin-right: auto;">
              <router-link to="/mosaico/covid/maps/brasilio/covid/microregion">
                <md-icon>map</md-icon>
              </router-link>
            </div>
            <p class="category">Mapas</p>
            <router-link to="/mosaico/covid/maps/brasilio/covid/microregion">
              <h3 class="title">
                Mapas por microregião
              </h3>
            </router-link>
          </template>

          <template slot="footer">
            <div class="stats">
              <md-icon>today</md-icon>
              Fonte: Brasil.Io
            </div>
          </template>
        </stats-card>
      </div>
    </div>
  </div>
</template>

<script>
import { StatsCard } from "@/components";

export default {
  components: {
    StatsCard
  },
  data() {
    return {};
  },
  mounted() {
    this.welcomeMessage();
  },
  props: {
    brasilIo: {
      type: String,
      default: require("@/assets/img/logo-brasil-io.png")
    }
  },
  methods: {
    welcomeMessage() {
      this.$emit("welcomeMessage", {
        title: "Analises COVID-19"
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.md-card-header {
  min-height: 110px;
}

h3 {
  min-height: 76px;
  margin-bottom: 1px;
}
</style>
